@import "prefixer.less";

.modern-link(@col-default,@col-hover: darken(@col-default,20%)) {
    text-decoration: none;
    display: inline-block;
    .transition(all .2s);

    span {
        color: @col-default;
        position: relative;
        border-bottom: 1px solid lighten(@col-default, 10%);
        .transition(all .2s);

        &:after {
            display: block;
            content: "";
            border-bottom: 1px solid @col-hover;
            position: absolute;
            bottom: -1px;
            left: 50%;
            width: 0%;
            .transition(all .2s);
        }
    }

    &:hover, &.active {
        span {
            color: @col-hover;

            &:after {
                left: 0%;
                width: 100%;
            }
        }
    }

    &.active {
        font-weight: bold;
    }
}

.modern-pseudo-link(@col-default,@col-hover: darken(@col-default,20%)) {
    .modern-link(@col-default, @col-hover);

    span {
        border-bottom-style: dotted;
    }
}

.modern-link {
    .modern-link(#0066cc);
}

.modern-pseudo-link {
    .modern-pseudo-link(#0066cc);
}