@import "slim-layout/vars.less";
@import "slim-layout/header.less";
@import "slim-layout/footer.less";

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}
.wrapper{
    height: auto !important;
    height: 100%;
    /*    margin: 0 0 -206px 0;  отрицательный нижний отступ на высоту футера */
    padding: 0;
    min-height: 100%;
    .row-clearfix();
}
.wrapped {
    padding-bottom: 15px;
}

.backtotop{
    position: fixed;
    z-index: 20;
    top:0px;
    left:0px;
    width:0px;
    height:100%;
    cursor:pointer;
    display:none;

    .anchor{
        position:absolute;
        top:-100px;
        left:0px;
        background: white;
        border:2px solid #658dc7;
        margin: 28px;
        padding:10px 10px;
        border-radius: 5px;
        z-index: 2000;
        width: 20px;
    }

    .a-holder{
        -webkit-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        transition: all 0.2s ease;
        position: absolute;
        left:0px;
        top:0px;
        height:100%;
        width:100px;
    }

    &:hover{
        .a-holder{
            background: rgba(101,151,199,0.4)
        }
    }
}