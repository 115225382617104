@import "../prefixer.less";
@import "../modern-link.less";
@import "../login-widget.less";
@import "../material-social-icons.less";
@import "../material-shadow.less";
@import "../for.less";

@border-radius: 3px;
@border-height: 2px;
@spacing: 5px;
@height: 36px;
@total-height: @height + @spacing*2 + @border-height;

@background: #f9f9f9;
@background-dark: #3c444f;
@color-border-dark: darken(@background-dark, 20%);
@color-border: #eeeeee;
@color-text: #222;
@color-decoration: #a2a2a2;
@color-link: #6aaee3;
@color-link-hover: #4e7fbe;
@color-menu-hover: #6d747c;

@full-content-height: 416px;

.row-clearfix {
    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }
    &:after {
        clear: both;
    }
}