@import "../prefixer.less";

.new-year-2017 {
    .wrapper {
        position: relative;
    }

    .new-year-2017__container {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
    }

    .new-year-2017__container-block-1,
    .new-year-2017__container-block-2,
    .new-year-2017__container-block-3 {
        position: absolute;
        content: '';
        width: 8000px;
        height: 16000px;
        top: -8000px;
        left: -4000px;
        z-index: 0;
        background-size: 200px;
        opacity: .5;
    }

    .new-year-2017__container-block-1 {
        background-image: url("/common-static/img/new-year-background/content_sn1_2.png");
        animation: snow 300s linear infinite;
    }

    .new-year-2017__container-block-2 {
        background-image: url("/common-static/img/new-year-background/content_sn2_2.png");
        animation: snow 600s linear infinite;
    }

    .new-year-2017__container-block-3 {
        background-image: url("/common-static/img/new-year-background/content_sn3_2.png");
        animation: snow 900s linear infinite;
    }

    //.slim-header {
    //    margin-bottom: 0;
    //}
    //
    //footer.footer {
    //    margin-top: 0;
    //}
    //
    .container_12 {
        position: relative;
        background: #fff;
        padding: 10px 12px 20px 12px;
    }

    .main-logo {
        position: relative;
        display: inline-block;

        &:before {
            content: '';
            position: absolute;
            background-image: url("/common-static/img/new-year-background/shapka.png");
            background-size: contain;
            background-repeat: no-repeat;
            top: -7px;
            left: -7px;
            width: 26px;
            height: 20px;
        }
    }

    @keyframes snow {
        100% {
            transform: translate(50%, 50%);
        }
    }
}