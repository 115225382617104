@import "prefixer.less";
@import "modern-link.less";

@base-color: #222;

@col-static: #e9e9e9;
@col-active: #0066cc;

@col-error: #ff7f7f;
@col-error-text: white;

@col-button: @col-active;
@col-button-text: white;

@border-radius: 3px;


.login-widget{
    text-align: center;
    color: #222;

    .input-text{
        width: 100%;
        padding: 5px;
        margin: 0px 0px 15px 0px;
        .border-radius(@border-radius);
        border: 1px solid @col-static;
        .box-sizing(border-box);
        .transition(all .2s);

        &:hover{
            border-color: darken(@col-static,20%);
        }
        &:focus{
            outline: none;
            border-color: @col-active;
        }
    }

    .button{
        padding: 10px 0px;
        width: 150px;
        border: none;
        background: lighten(@col-button,20%);
        border-bottom: 3px solid @col-button;
        color: @col-button-text;
        cursor: pointer;
        font-size: 16px;
        letter-spacing: 1px;
        .border-radius(@border-radius);
        .transition(all .2s);

        &:focus,
            &:hover{
            background: lighten(@col-button,15%);
        }
        &:active{
            margin-top: 3px;
            background: lighten(@col-button,15%);
            border-bottom-width: 0px;
        }
    }

    .link{
        .modern-link(@col-active);
        font-size: 14px;
    }

    .login-blocks{
        margin: 20px 0px;

        &:before,
            &:after {
            content: " "; // 1
            display: table; // 2
        }
        &:after {
            clear: both;
        } 

        .login-block{
            display: block;
            width: 380px;
            margin: 0px 85px;
            float: left;
            .box-sizing(border-box);

            .title{
                font-weight: bold;
                font-size: 18px;
                text-align: left;
                margin-bottom: 20px;
            }

            .button{
                float: left;
            }

            .reglink{
                float: right;
                line-height: 38px;
            }

            .reglinks{
                margin-top: 10px;
                float: none;
                clear: both;
                line-height: 38px;
                
                a{
                    margin-right: 10px;
                    
                    &:last-child{
                        margin-right: 0px;
                    }
                }
            }
        }
    }

    .error-list{
        background: @col-error;
        color: @col-error-text;
        padding: 10px;
    }

    .soc-enter{
        background: @col-static;
        padding: 10px;
        margin-top: 40px;

        .soc-title{
            display: inline-block;
            margin-top: -30px;
            padding: 10px 40px;
            line-height: 18px;
            font-size: 15px;
            font-weight: normal;
            background: @col-static;
            .border-radius(@border-radius);
        }

        .icons-holder{
            margin: 0 auto;
            vertical-align: bottom;
            text-align: center;
        }

        #login_form{
            float: none;
            clear: both;
            line-height: 27px;
            width: 335px;
            
            label{
                line-height: 18px;
                display: block;
                margin-bottom: 2px;
            }

            .input-text{
                float: left;
                margin-right: 10px;
                margin-top: 1px;
                margin-bottom: 0px;
            }
            .button{
                font-size: 14px;
                padding: 4px;
                width: 80px;
                float: left;
                margin-right: 4px;
            }
        }

    }
}