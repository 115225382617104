/* share widget */
.sendto {
    display: inline-block;
    width: 100%;
    vertical-align: baseline;

    .holder{
        span.stxt {
            font-size: 16px;
            line-height: 30px;
            float: left;
            margin-right: 5px;
        }
        span.icons{
            float:right;
        }

        .share-icon a{
            background-image: url("/common-static/img/share-icons-allx30.gif");
            display: inline-block;
            height: 30px;
            width: 30px;
            vertical-align: top;
            /* zoom:1; *display: inline;*/
        }
        .share-icon.facebook a {
            background-position: 0 0;
        }
        .share-icon.vkontakte a {
            background-position: -90px 0;
        }
        .share-icon.twitter a {
            background-position: -120px 0;
        }
        .share-icon.ok a {
            background-position: -180px 0;
        }
    }

}