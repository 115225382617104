@import "/common-static/weather/css/weather-list.css";
@import "vars.less";

// Цвета использовать ТОЛЬКО из палитры нашего логотипа ИЛИ http://www.google.com/design/spec/style/color.html#color-color-palette
@z-index: 10000;

.slim-header-shadow {
    .gradient(transparent, rgba(0, 0, 0, .15), rgba(0, 0, 0, 0));
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    visibility: hidden;
    opacity: 0;
    z-index: @z-index - 1;
    .transition(all .3s);

    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.slim-header-pusher {
    //height: @total-height;
}

.slim-header {
    position: relative;
    z-index: @z-index;
    border-bottom: @border-height solid @color-border;
    background: @background;
    font-size: 14px;
    line-height: 1.45;
    .z-depth(1);
    //position: fixed;
    //top: 0px;
    //left: 0px;
    //width: 100%;

    * {
        font-family: "Open Sans", sans-serif;
    }

    .container {
        margin: 0px auto;
        position: relative;
        height: 100%;
    }

    .header-row {
        .row-clearfix();
        position: relative;
    }

    .logo-row {
        height: @height + @spacing*2;
        position: relative;

        .logo {
            position: absolute;
            left: 0px;
            top: 0px;

            a {
                display: inline-block;
                padding: (@spacing + 3px) 5px (@spacing + 1px) 5px;
                text-decoration: none;
                color: @color-link;

                .row-clearfix();

                .section-name,
                .brand {
                    display: block;
                    float: left;
                    height: @height - 4px;
                    opacity: 1;
                }
                .brand {
                    .transition(opacity .2s);
                }
                .section-name {
                    font-family: "Open sans", sans-serif;
                    font-weight: 300;

                    font-size: @height - 3px;
                    line-height: @height - 6px;
                    margin-top: 0px;
                    color: @color-text;
                    letter-spacing: -2px;
                    text-transform: lowercase;
                    margin-left: 5px;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }

                &.section-name-holder {
                    //margin-left: @spacing;
                }
            }

            .menu-button {
                display: block;
                height: @height - 18px;
                cursor: pointer;
                vertical-align: top;
                position: relative;
                float: left;
                margin-right: 0;
                padding: 14px 10px;
                width: 15px;
                box-sizing: content-box;

                .bar {
                    display: block;
                    width: 100%;
                    height: 2px;
                    background: @color-text;
                    margin: 3px 0px;
                }

                &.signal::after {
                    content: "";
                    background: #00E676;
                    position: absolute;
                    top: 25px;
                    right: -2px;
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    //                    box-shadow: 0px 0px 5px 2px @color-link;
                    .animation(signal 2s infinite);

                    .keyframes2(signal; { 0% {
                        .transform(scale(1));
                    } 50% {
                          .transform(scale(.5));
                      } 100% {
                            .transform(scale(1));
                        } });
                }
            }

            .hoverable {
                border-bottom: 2px solid transparent;

                &:hover {
                    border-color: @color-link-hover;
                }
            }
        }
        .content {
            .box-sizing(border-box);
            padding: 0px 250px 0px 204px;
            height: 100%;
        }

        .profile {
            @profile-width: 250px;

            position: absolute;
            right: 0px;
            top: 0px;
            height: @height;
            margin: @spacing;
            width: @profile-width;
            z-index: 12;

            .slim-profile-menu {
                vertical-align: middle;
                height: 100%;
                text-align: right;
                position: relative;

                @a-fast: .2s;
                @a-slow: .4s;
                @a-delay: .1s;

                .user {
                    position: relative;
                    display: inline-block;
                    z-index: 2;
                    width: @profile-width - 6px;

                    .displayname {
                        position: relative;
                        z-index: 2;
                        vertical-align: middle;
                        text-decoration: none;

                        .fix-avatar-resizing {
                            display: inline-block;
                        }

                        .user-avatar {
                            width: 36px;
                            height: 36px;
                            display: block;
                            vertical-align: middle;
                            border: 1px solid @color-link;
                            .border-radius(100%);
                            .transform(translate3d(0px, 0px, 0px) scale(1));
                            .transition(transform @a-fast);
                        }
                        span {
                            display: inline-block;
                            vertical-align: top;
                            white-space: nowrap;
                            line-height: 36px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            width: @profile-width - 50px;
                            font-weight: bold;
                            color: @color-link;
                        }

                    }

                    @point-width: 600px;
                    @menu-padding: 20px;
                    @menu-width: 250px;

                    .user-menu {
                        position: absolute;
                        right: 0px;
                        top: 0px;
                        padding: @menu-padding;
                        margin: -@menu-padding -@menu-padding 0px 0px;
                        width: @menu-width;
                        height: 0px;
                        overflow: hidden;

                        .holder {
                            overflow: hidden;
                            position: relative;
                            .transition(all 0s 0s);
                            .z-depth(0);

                            .point {
                                background: white;
                                width: @point-width;
                                height: @point-width;
                                position: absolute;
                                left: 50%;
                                top: -50%;
                                margin-left: -@point-width/2;
                                margin-top: -40px;
                                .border-radius(100%);
                                .transition(all 0s 0s);
                                .transform(scale(0));
                            }

                            .big-avatar-holder {
                                text-align: center;
                                height: 140px;
                                margin: 10px 0px;
                                position: relative;

                                .runas-avatar-holder {
                                    .runas-avatar {
                                        width: 120px;
                                        height: 120px;
                                        margin-left: 100px;
                                        margin-top: 10px;
                                        border-radius: 100%;
                                        opacity: 0;
                                        border: 3px solid @color-link;
                                    }
                                }

                            }

                            .new-messages {
                                background: red;
                                color: white;
                                font-weight: bold;
                                border-radius: 40px;
                                position: relative;
                                float: left;
                                padding: 5px;
                                margin: -40px 0px 0px 65px;
                                z-index: 3;
                                text-align: center;
                                min-width: 20px;
                                opacity: 0;
                                text-decoration: none;
                            }

                            .username {
                                position: relative;
                                color: @color-text;
                                text-align: center;
                                font-weight: bold;
                                opacity: 0;
                                margin: 10px 0px;
                                .transform(translateY(-10px));

                                a {
                                    color: @color-link;
                                    text-decoration: none;
                                    display: block;
                                    .transition(all .2s);
                                }

                                &:hover a {
                                    color: @color-link-hover;
                                }
                            }

                            .actions {
                                padding: 0px;
                                margin: 0px 0px 10px 0px;
                                list-style-type: none;

                                li {
                                    opacity: 0;
                                    .transform(translateY(-10px));
                                    .transition(all 0s 0s);

                                    > a {
                                        padding-right: 16px;
                                        margin: 0px;
                                        border-left: 2px solid transparent;
                                        color: @color-text;
                                        span {
                                            color: @color-text;
                                        }

                                        &:hover {
                                            background: @color-link;
                                            border-left-color: @color-link-hover;

                                            span {

                                                color: white;

                                            }
                                        }
                                    }
                                }
                            }

                            .logout {
                                float: right;
                                font-size: 14px;
                                position: relative;
                                margin: 5px;
                                text-decoration: none;
                                opacity: 0;

                                &:hover {
                                    color: @color-link-hover;
                                }
                            }
                        }
                    }

                    &.runas {
                        .displayname {
                            span {
                                color: #F44336;
                            }
                            .user-avatar {
                                border-color: #F44336;
                            }
                        }
                        .user-menu {
                            .username {
                                a {
                                    color: #F44336;
                                }
                            }
                            .new-messages {
                                margin-left: 20px;
                            }
                        }
                    }

                    &:hover {
                        .displayname {
                            span {
                                opacity: 0;
                                .transition(all @a-fast);
                            }
                            .user-avatar {
                                .transform(translate3d(-107px, 62px, 0px) scale(3.88888));
                            }
                        }
                        &.runas {
                            .displayname {
                                .user-avatar {
                                    border-color: #F44336;
                                    .transform(translate3d(-107px, 62px, 0px) scale(3.88888));
                                }
                            }
                        }
                        .user-menu {
                            height: auto;

                            .holder {
                                background: white;

                                .z-depth(2);
                                .transition(all @a-fast @a-slow);

                                .point {
                                    .transform(scale(1));
                                    .transition(all @a-slow @a-fast);
                                }

                                .big-avatar-holder {
                                    .runas-avatar {
                                        .opacity(1);
                                        .transition(all @a-fast @a-fast + @a-delay * 7);
                                    }
                                }

                                .new-messages {
                                    .opacity(1);
                                    .transition(all @a-slow @a-fast);
                                }

                                .username {
                                    .transform(translateY(0));
                                    .opacity(1);
                                    .transition(all @a-fast @a-fast);
                                }

                                .logout {
                                    .opacity(1);
                                    .transition(all @a-fast @a-fast + @a-delay * 7);
                                }

                                .actions {
                                    li {
                                        opacity: 1;
                                        .transform(translateY(0));

                                        .transition(all @a-fast);
                                        .for(15);
                                        .-each(@i) {
                                            &:nth-child(@{i}) {
                                                .transition-delay(@a-fast + @a-delay * ( @i - 1 ));
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &:after {
                    content: "";
                    vertical-align: middle;
                    width: 0px;
                    //background: red;
                    height: 100%;
                    display: inline-block;
                }

                .auth-enter {
                    .modern-pseudo-link(@color-link, @color-link-hover);
                    font-size: 14px;
                }
            }
        }
    }

    .default-menu {
        display: block;
        margin: 0px;
        padding: 0px;
        list-style-type: none;
        .box-sizing(border-box);

        li {
            margin: 0px;

            a {
                text-decoration: none;
                display: block;
                color: @color-text;
                position: relative;
                font-size: 14px;
                line-height: 10px;
                //                padding-top: 10px;
                //                padding-bottom: 12px;
                //                padding-left: 16px;
                //                padding-right: 16px;
                text-align: left;
                padding: 10px 0px 12px 16px;
                .box-sizing(border-box);

                .hint-holder,
                .vse-ui-icon {
                    display: none !important;
                }

                .btn-link-text {
                    text-decoration: none;
                }
            }
        }
    }

    .link-menu {
        a:hover {
            color: @color-link-hover;
        }
    }

    .bubble-menu {
        .bubble-item {
            display: block;
            position: relative;
            padding: 0px;
            overflow: hidden;

            .point {
                position: absolute;
                display: block;
                width: 310px;
                height: 310px;
                margin-left: -155px;
                margin-top: -155px;
                left: 0px;
                top: 0px;
                border-radius: 100%;
                .transform(scale(0));
                .transition(all .4s);
                .transition-property(transform);
            }

            a {
                position: relative;
                display: block;
            }

            &:hover {
                .point {
                    .transform(scale(1));
                }
            }

            &.active {
                .point {
                    .transform(scale(0));
                }
            }
        }
    }

    .full-header {
        position: absolute;
        /*background: @background-dark;*/
        left: 50%;
        top: @total-height;
        color: white;
        visibility: hidden;
        .transform(translateZ(0) scale(1, 0));
        .transform-origin(10px 0%);
        .transition(transform .2s);

        .container {
            padding: 0px;
            position: relative;
            .z-depth(3);
            background: white;
            .box-sizing(border-box);
            height: @full-content-height;
            overflow: hidden;
            .transform(scale(0, 1));
            .transition(all .3s);
            .transform-origin(10px 0%);
        }

        &.active {
            visibility: visible;
            .transform(translateZ(0) scale(1, 1));
            .container {
                .transform(scale(1, 1));
            }
        }

        .all-realms {
            width: 170px;
            top: 0px;
            left: 0px;
            height: 100%;
            position: absolute;
            background: white;
            z-index: 1;
            box-shadow: inset -3px 0px 0px -2px @color-border;

            li {

                .point {
                    background: lighten(@color-link, 20%);
                }

                &:hover {
                    .point {
                        .transform(scale(1));
                    }
                }

                &.active {
                    background: @color-link;

                    .point {
                        .transform(scale(0));
                    }
                    a {
                        color: white;
                    }
                }
            }
        }

        .full-content {
            width: 100%;
            padding-left: 170px;
            position: relative;
            height: 100%;
            .box-sizing(border-box);

            .content-section {
                @fadeInTime: 0.3s;

                overflow: hidden;
                position: absolute;
                top: 0px;
                visibility: hidden;
                height: 100%;
                opacity: 0;
                .transition(opacity 0s @fadeInTime);

                &:after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 900px;
                    height: 900px;
                    margin-top: -450px;
                    margin-left: -450px;
                    background: white;
                    z-index: 100;
                    .opacity(1);
                    .border-radius(100%);

                    .transition(all @fadeInTime);
                }

                &.active {

                    &:after {
                        .opacity(0);
                        .transform(scale(0));
                        .transition(all 0s);
                    }

                    visibility: visible;
                    opacity: 1;
                }

                .content-row {
                    .row-clearfix();

                    .content-block {
                        float: left;
                        /*margin-left: 10px;*/
                        .row-clearfix();
                    }
                }

                .default-pseudo-link {
                    .modern-pseudo-link(@color-link, @color-link-hover);
                }
                .default-link {
                    font-size: 14px;
                    .modern-link(@color-link, @color-link-hover);
                }

                .realm-title {
                    font-weight: 400;
                    text-transform: uppercase;
                    color: @color-text;
                    padding: 0px;

                    a {
                        color: @color-text;
                        text-decoration: none;
                    }
                }

                .default-card {
                    .card-holder {
                        display: inline-block;
                        border-radius: @border-radius;
                        cursor: pointer;
                        .transition(transform .2s);

                        .shadow {
                            display: block;
                            .z-depth(1);
                            .transition(box-shadow .2s);

                            .pic {
                                width: 100%;
                                height: auto;
                                display: block;
                            }

                            .text {
                                display: block;
                                padding: 5px;
                                text-decoration: none;
                                font-size: 14px;
                                line-height: 18px;
                                font-weight: 600;
                                color: @color-text;
                                .box-sizing(border-box);
                            }

                            &:hover {
                                .z-depth(2);
                            }
                        }
                    }
                }

                .default-tile {
                    display: block;
                    position: relative;
                    overflow: hidden;

                    .tile-image {
                        min-width: 100%;
                        min-height: 100%;
                    }

                    .title-holder {
                        position: absolute;
                        z-index: 2;
                        bottom: 0px;
                        left: 0px;
                        width: 100%;
                        display: block;
                        padding: 5%;
                        .gradient(transparent, rgba(0, 0, 0, 0), rgba(0, 0, 0, .4));
                        .box-sizing(border-box);

                        .title {
                            font-size: 18px;
                            font-weight: 400;
                            color: white;
                            display: block;
                        }

                        .sub-title {
                            margin-top: 10px;
                            font-weight: 400;
                            font-size: 12px;
                            display: block;
                            color: white;
                        }

                        .icons {
                            position: absolute;
                            bottom: 5px;
                            right: 10px;
                            color: white;
                            vertical-align: middle;

                            .icon {
                                font-size: 10px;
                                padding: 2px 0px;
                                color: white;

                                &:before {
                                    content: "";
                                    display: inline-block;
                                    width: 14px;
                                    height: 14px;
                                    vertical-align: middle;
                                    margin-right: 2px;
                                }

                                &.views {
                                    &:before {
                                        background: url('/common-static/img/slim-header/eye-white-14.png');
                                    }
                                }
                            }
                        }
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                        top: 0px;
                        left: 0px;
                        background: rgba(0, 0, 0, .1);
                        opacity: 0;
                        .transition(opacity .2s);
                    }

                    &:hover {
                        &:after {
                            opacity: 1;
                        }
                    }

                    &.strong-overlay {
                        .title-holder {
                            .linear-gradient-top(transparent, rgba(0, 0, 0, 0), 0%, rgba(0, 0, 0, .4), 40%);
                        }
                    }
                }

                .default-text-item {
                    border-bottom: 1px solid @color-border;
                    display: block;
                    padding: 10px;
                    text-decoration: none;
                    position: relative;
                    background: white;
                    margin: 0px;
                    .box-sizing(border-box);
                    .z-depth(0);
                    .transition(background .2s);
                    .row-clearfix();

                    .preview {
                        float: left;
                        margin-right: 10px;
                    }

                    .title {
                        text-decoration: none;
                        font-size: 14px;
                        display: block;
                        color: @color-text;
                        font-weight: 600;

                        .transition(color .2s);
                    }

                    .description {
                        font-size: 12px;
                        color: @color-text;
                    }

                    .icons {
                        position: absolute;
                        bottom: 10px;
                        right: 10px;
                        color: white;
                        vertical-align: middle;

                        .icon {
                            font-size: 10px;
                            padding: 2px 0px;
                            color: black;

                            &:before {
                                content: "";
                                display: inline-block;
                                width: 14px;
                                height: 14px;
                                vertical-align: middle;
                                margin-right: 2px;
                            }

                            &.views {
                                &:before {
                                    background: url('/common-static/img/slim-header/eye-black-14.png');
                                }
                            }
                        }
                    }

                    &:hover {
                        z-index: 2;
                        background: #E1F5FE;
                    }
                }

                .default-button {
                    @button-default-color: white;

                    display: inline-block;
                    font-size: 14px;
                    line-height: 10px;
                    font-weight: 600;
                    padding: 13px 18px 13px 18px;
                    text-decoration: none;
                    text-transform: uppercase;
                    color: @color-text;

                    &.rised {
                        border-radius: @border-radius;
                        background: @button-default-color;
                        .z-depth(1);
                    }

                    &:hover {
                        background: darken(@button-default-color, 3%);
                        color: @color-text;

                        &.rised {
                            .z-depth(2);
                        }
                    }

                    &.colored {
                        background: @color-link;
                        color: white;

                        &:hover {
                            background: @color-link-hover;
                            color: white;
                        }
                    }
                }

                .default-search-pair {

                    display: inline-block;
                    height: 30px;
                    width: 200px;
                    vertical-align: middle;
                    position: relative;
                    /*.transform(translateY(-300px));*/
                    /*.transition(transform 0s 0s);*/

                    .input {
                        position: absolute;
                        left: 0px;
                        z-index: 2;
                        background-color: transparent;
                        border: none;
                        color: @color-text;
                        height: 31px;
                        width: 200px;
                        padding: 5px;
                        font-size: 18px;
                        line-height: 30px;
                        border-bottom: 2px solid transparent;

                        .transition(border .0s .0s);
                        .box-sizing(border-box);

                        &:focus {
                            border-color: @color-link-hover;
                            .transition(border .0s .4s);

                            & + .icon {

                                path {
                                    stroke-dashoffset: 275;
                                    stroke-dasharray: 200, 76;
                                }
                            }
                        }
                    }

                    .icon {
                        cursor: pointer;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        z-index: 1;

                        path {
                            stroke: @color-link-hover;
                            stroke-width: 2px;
                            fill: transparent;
                            stroke-dasharray: 75, 200;
                            stroke-dashoffset: 75;
                            stroke-linejoin: round;
                            .transition-duration(.4s);
                            .transition-delay(0s);
                            .transition-timing-function(cubic-bezier(0, .5, .5, 1));
                            .transition-property(stroke-dasharray, stroke-dashoffset;);
                        }
                    }
                }

                .default-bar {
                    background: #fafafa;
                    position: relative;
                    height: 48px;
                    z-index: 2;
                    .z-depth(1);
                    .box-sizing(border-box);
                    .row-clearfix();
                }

                .default-tabs-block {
                    position: relative;
                    overflow: hidden;
                    height: 100%;

                    .tabs {
                        float: left;
                        position: relative;
                        z-index: 2;
                        height: 48px;
                        .box-sizing(border-box);
                        .row-clearfix();

                        .tab {
                            cursor: pointer;
                            float: left;
                            text-transform: uppercase;
                            font-weight: 600;
                            height: 48px;
                            font-size: 14px;
                            line-height: 10px;
                            padding: 18px 20px 20px 20px;
                            .transition(all .2s);
                            .box-sizing(border-box);

                            &:hover,
                            &.active {
                                color: @color-link-hover;
                            }
                        }
                    }

                    .tab-selector-holder {
                        position: absolute;
                        bottom: 0px;
                        left: 0px;
                        width: 100%;
                        height: 2px;

                        .tab-selector {
                            height: 100%;
                            background: @color-link-hover;
                            width: 1px;
                            left: 0px;

                            .transition(transform .4s);
                        }
                    }

                    .tabs-content {
                        width: 100%;
                        overflow: hidden;
                        height: @full-content-height - 48px;

                        .content-holder {
                            .transition(transform .2s);

                            .content-block {
                                position: relative;
                            }
                        }

                    }
                }

                .default-title {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 12px;
                    padding: 10px 16px 12px 16px;
                }

                /* стандартный вид ссылки ИСЧО */

                .default-more-link {
                    position: absolute;
                    bottom: 10px;
                    left: 10px;
                }

                /* анимация */

                .sequence-animation {

                    .sequence-cube {
                        @animation-time: 0s;
                        @animation-delay: @fadeInTime;

                        .sequence-card {
                            .transition(all 0s @fadeInTime;);
                            .transform(scale(0));
                        }
                    }
                }

                .slider-menu-animation {
                    position: relative;
                    z-index: 2;
                    .transition(all 0s @fadeInTime);
                    .transform(translateX(-100%));
                }
                .slider-single-animation,
                .slider-content-animation {
                    position: relative;
                    z-index: 1;
                    .transform(translateX(-770px));
                    .transition(all 0s @fadeInTime);
                }

                &.active {

                    // анимация вылетающих панелей
                    .slider-menu-animation {
                        .transition(transform .2s @fadeInTime);
                        .transform(translateX(0px));
                    }
                    .slider-content-animation {
                        .transition(transform .5s @fadeInTime + .2s);
                        .transform(translateX(0));
                    }
                    .slider-single-animation {
                        .transition(transform .4s @fadeInTime);
                        .transform(translateX(0));
                    }

                    // Анимация появляющихся кубов
                    .sequence-animation {
                        .sequence-cube {
                            @animation-time: .2s;
                            @animation-delay: .1s + @fadeInTime;

                            .sequence-card {
                                .transition(all @animation-time @fadeInTime);
                                .transform(scale(1));
                            }

                            .for(7);
                            .-each(@i) {
                                &:nth-child(@{i}) {
                                    .sequence-card {
                                        .transition-delay(@animation-time*(@i - 2) + @animation-delay);
                                    }
                                }
                            }

                        }
                    }
                }

                /* вид новости с небольшой картинкой */
                .news-text {
                    background: white;
                    margin-top: 5px;
                    height: 35px;
                    display: block;
                    text-decoration: none;
                    padding: 5px;
                    .transition(all .2s);
                    .row-clearfix();

                    img {
                        float: left;
                        margin: 0px 5px 0px 0px;
                    }
                    span {
                        font-size: 13px;
                        line-height: 16px;
                    }

                    &:first-child {
                        margin-top: 0px;
                    }

                    &:hover {
                        background: lighten(@color-link, 32%);

                        span {
                            color: @color-text;
                        }
                    }
                }

                &.vse {

                    .big-articles {
                        width: 385px;

                        float: left;
                        border-right: 1px solid @color-border;
                        .box-sizing(border-box);

                        .big-article-holder {
                            margin: 4px;
                            position: relative;
                            .opacity(0);
                            .transform(translateY(-50px) scaleY(0.9));
                            .transition-delay(@fadeInTime);
                            .transition-property(all);

                            .big-article {
                                margin: 0px;
                                height: 202px;

                                &:hover {
                                    .z-depth(2);
                                }
                            }

                            &:hover {
                                z-index: 2;
                            }
                        }
                    }

                    .articles-and-social {
                        width: 385px;
                        float: left;

                        .articles {
                            list-style-type: none;
                            padding: 0px;
                            margin: 0px;

                            .article-holder {
                                margin: 0px;
                                .opacity(0);
                                .transform(translateY(-20px) scaleY(0.9));
                                .transition(all 0s @fadeInTime);

                                .article {

                                    .preview {
                                        width: 83px;
                                        height: 83px;
                                    }

                                }
                            }
                        }

                        .social-icons {
                            height: 104px;
                            padding-top: 28px;
                            .box-sizing(border-box);

                            .material-social-icon-holder {
                                .opacity(0);
                                .transform(translateY(-200px));
                                .transition(all 0s @fadeInTime);

                                display: block;
                                margin-right: 12px;
                                float: left;

                                &:first-child {
                                    margin-left: 12px;
                                }
                            }
                        }
                    }

                    &.active {
                        @step: .1s;

                        .big-articles {
                            @baseDelay: @fadeInTime;

                            .big-article-holder {
                                .opacity(1);
                                .transition-duration(.2s);
                                .transform(translateY(0) scaleY(1));

                                .for(2);
                                .-each(@i) {
                                    &:nth-child(@{i}) {
                                        .transition-delay(@baseDelay + @step * (@i - 1));
                                    }
                                }
                            }
                        }

                        .articles-and-social {

                            @baseDelay: @fadeInTime + .2s;

                            .article-holder {
                                .opacity(1);
                                .transition-duration(.2s);
                                .transform(translateY(0) scaleY(1));

                                .for(3);
                                .-each(@i) {
                                    &:nth-child(@{i}) {
                                        .transition-delay(@baseDelay + @step * (@i - 1));
                                    }
                                }
                            }

                            .social-icons {
                                @baseDelay: @fadeInTime + 0.4s;

                                .material-social-icon-holder {
                                    .opacity(1);
                                    .transform(translateY(0px));
                                    .z-depth(0);
                                    .transition-duration(.2s);
                                    .transition-delay(@baseDelay);

                                    .for(7);
                                    .-each(@i) {
                                        &:nth-child(@{i}) {
                                            .transition-delay(@baseDelay + (@step * @i));
                                        }
                                    }
                                }
                            }
                        }
                    }

                }

                &.weather {
                    .weather-block {
                        height: @full-content-height;
                        width: 770px;
                        overflow: hidden;
                        .row-clearfix();

                        .weather-title {
                            text-transform: uppercase;
                            margin-bottom: 20px;
                            font-size: 18px;
                            padding: 0px;
                            width: 400px;
                            color: @color-text;
                        }

                        .full-data {
                            float: left;
                            width: 225px;
                            padding: 10px;
                            position: relative;
                            z-index: 3;
                            height: @full-content-height;
                            background: white;
                            .box-sizing(border-box);

                            span {
                                color: @color-text;
                            }

                            .text {
                                margin: 10px 0px;
                                font-size: 14px;
                            }

                            .value {
                                font-weight: bold;
                            }
                        }

                        .bigpicture {
                            float: left;
                            height: @full-content-height;
                            width: 545px;
                            position: relative;
                            background-size: cover;
                            padding: 1px;
                            .box-sizing(border-box);

                            .current-weather {
                                position: relative;
                                z-index: 2;
                                .opacity(0);
                                .transform(translateY(390px));
                                .transition(all 0s @fadeInTime);

                                .icobig {
                                    margin: 30px auto 0px auto;
                                    left: 0px;
                                }

                                .text,
                                .temp {
                                    color: white;
                                    text-align: center;
                                    text-transform: uppercase;
                                    font-weight: 300;
                                }

                                .temp {
                                    font-size: 82px;
                                }

                                .text {
                                    font-size: 18px;
                                    font-weight: bold;
                                }
                            }

                            .forecast {
                                position: absolute;
                                bottom: 0px;
                                z-index: 2;

                                .timerow {
                                    display: inline-block;
                                    text-align: center;
                                    vertical-align: bottom;
                                    margin: 5px 7px;
                                    width: 50px;
                                    .transform(translateY(150px));
                                    .transition(all 0s @fadeInTime);

                                    .temp_graf,
                                    .temp_text,
                                    .time {
                                        display: block;
                                        color: white;
                                        margin: 5px auto;
                                    }

                                    .temp_text {
                                        font-weight: 300;
                                        font-size: 30px;

                                        &:after {
                                            content: "\00b0";
                                        }
                                    }

                                    .temp_graf {
                                        background: white;
                                        width: 100%;
                                        text-align: center;
                                        .transition(all 0s @fadeInTime);
                                        .transform-origin(50% 100%);
                                        .transform(scaleY(0));

                                        .ico30 {
                                            margin-top: 0px;
                                            display: inline-block;
                                            vertical-align: middle;
                                            margin-right: -5px;
                                        }

                                        &:after {
                                            content: "";
                                            vertical-align: middle;
                                            height: 100%;
                                            width: 0px;
                                            display: inline-block;
                                        }
                                    }

                                    &:first-child {
                                        margin-left: 12px;
                                    }

                                    &.act {

                                        .temp_text {
                                            font-weight: 600;
                                        }

                                    }
                                }
                            }

                            &:before {
                                content: "";
                                position: absolute;
                                left: 0px;
                                top: 0px;
                                width: 100%;
                                height: 100%;
                                z-index: 1;
                                .gradient(transparent, rgba(0, 0, 0, .1), rgba(0, 0, 0, .4));
                                .transition(transform 0s @fadeInTime);
                            }
                        }

                        .default-more-link {
                            .transform(scale(0));
                            .transition(transform 0s @fadeInTime);
                        }
                    }

                    &.active {
                        .weather-block {

                            .current-weather {
                                .opacity(1);
                                .transform(translateY(0px));
                                .transition(all .4s @fadeInTime + .6s);
                            }

                            .forecast {
                                @stepDelay: .05s;
                                @baseDelay: @fadeInTime + .7s;
                                @animationTime: .3s;

                                .step(@step:1) {
                                    &:nth-child(@{step}) {
                                        .transition-delay(@baseDelay + @stepDelay * @step);

                                        .temp_graf {
                                            .transform(scaleX(1));
                                            .transition-delay(@baseDelay + @stepDelay * @step + @animationTime);
                                        }
                                    }
                                }

                                .timerow {
                                    .transform(translateY(0px));
                                    .transition(transform @animationTime @baseDelay);

                                    .temp_graf {
                                        .transform(scaleY(100%));
                                        .transition(all @animationTime @baseDelay + @animationTime);
                                    }

                                    .step(1);
                                    .step(2);
                                    .step(3);
                                    .step(4);
                                    .step(5);
                                    .step(6);
                                    .step(7);
                                }
                            }

                            .default-more-link {
                                .transition-duration(.2s);
                                .transition-delay(@fadeInTime + 1s);
                                .transform(scale(1));
                            }
                        }
                    }
                }

                &.news {

                    .news-feed-block {

                        @color-news-light: #EEEEEE; // 200
                        @color-news-bold: #616161;
                        overflow: hidden;
                        width: 770px;
                        height: @full-content-height;
                        .box-sizing(border-box);

                        .rubrics-block {
                            width: 225px;
                            float: left;
                            background: white;
                            height: @full-content-height;
                            position: relative;
                            z-index: 4;
                            .z-depth(1);

                            ul {
                                li {
                                    position: relative;

                                    &.flash {
                                        font-weight: bold;

                                        .title {
                                            color: #c02930;
                                        }
                                    }

                                    .point {
                                        width: 450px;
                                        height: 450px;
                                        margin: -225px;
                                        background: @color-news-light;
                                    }

                                    &:hover {
                                        .title {
                                            color: @color-text;
                                        }
                                    }

                                    &.active {
                                        background: @color-news-bold;

                                        .title {
                                            color: white;
                                        }
                                    }
                                }
                            }

                            .search-input {
                                padding: 17px 12px;
                                display: block;
                                border-bottom: 1px solid @color-border;
                                height: 64px;
                                .box-sizing(border-box);

                                .default-search-pair {

                                    display: inline-block;
                                    height: 30px;
                                    width: 200px;
                                    vertical-align: middle;
                                    position: relative;

                                    .input {

                                        &:focus {
                                            border-color: @color-news-bold;
                                        }
                                    }

                                    .icon {

                                        path {
                                            stroke: @color-news-bold;
                                        }
                                    }
                                }

                            }

                            .all-rubrics-holder {
                                border-top: 1px solid @color-border;
                                padding: 13px 0px;
                                text-align: center;
                                height: 64px;
                                .box-sizing(border-box);
                            }

                        }

                        .news-content-holder {
                            @content-width: 545px;

                            float: left;
                            width: @content-width;
                            height: @full-content-height;
                            overflow: hidden;
                            position: relative;
                            .box-sizing(border-box);

                            .content-block {
                                position: absolute;
                                overflow: hidden;
                                z-index: 2;
                                height: @full-content-height;
                                .transition(transform .3s .3s);
                                .transform(translateX(-100%));

                                &.active {
                                    z-index: 3;
                                    .transition-delay(0s);
                                    .transform(translateX(0));
                                }

                                .news-card {
                                    width: @content-width;
                                    padding: 11px 10px;

                                    &:last-child {
                                        border-bottom: none;
                                        padding: 12px 10px;
                                    }
                                }

                                &.flash {
                                    .tile {
                                        width: @content-width;
                                        height: 100%;

                                        .title-holder {

                                            .title,
                                            .sub-title {
                                                color: white;
                                            }
                                            .title {
                                                font-size: 39px;
                                            }
                                            .sub-title {
                                                font-size: 24px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.active {
                            .news-content-holder {
                                z-index: 5;
                            }
                        }
                    }
                }

                &.gk {

                    .magazines {
                        list-style-type: none;
                        padding: 0px;
                        margin: 40px 0px 0px 0px;
                        counter-reset: delay;

                        @width: 210px;
                        @height: 297px;

                        .magazine {
                            position: relative;
                            display: inline-block;
                            margin-right: 25px;
                            margin-left: 20px;
                            width: @width;
                            height: @height;
                            text-align: center;
                            vertical-align: top;
                            //                            .perspective(1000);

                            &:last-child {
                                margin-right: 0px;
                            }

                            .image {
                                vertical-align: middle;
                                display: block;
                                .perspective(1000);

                                .img-holder {
                                    width: @width;
                                    height: @height;
                                    position: relative;
                                    display: block;

                                    .transform-style(preserve-3d);
                                    .transition(all .2s);
                                    .z-depth(1);

                                    .averse,
                                    .reverse {
                                        position: absolute;
                                        top: 0px;
                                        left: 0px;
                                        width: 100%;
                                        height: 100%;
                                        .backface-visibility(hidden);
                                    }
                                    .averse {
                                        .transform(rotateY(0deg));
                                    }
                                    .reverse {
                                        background: white;
                                        .transform(rotateY(180deg));
                                    }
                                    .data {
                                        text-align: left;
                                        padding: 10px;
                                        .box-sizing(border-box);

                                        p {
                                            display: block;
                                            margin: 0px 0px 10px 0px;

                                            span {
                                                color: @color-text;
                                                display: block;

                                                &.key {
                                                    font-size: 12px;
                                                    font-weight: 600;
                                                }
                                                &.value {
                                                    //                                                    text-align: right;
                                                }
                                            }
                                        }
                                    }
                                }

                                &:hover {
                                    .img-holder {
                                        .transform(rotateY(180deg));
                                        .z-depth(2);
                                    }
                                }
                            }

                            &:after {
                                content: "";
                                display: inline-block;
                                vertical-align: middle;
                                width: 0px;
                                height: 100%;
                            }
                        }
                    }

                    .default-more-link {
                        .transform(scale(0));
                        .transition(transform 0s @fadeInTime);
                    }

                    &.active {
                        .default-more-link {
                            .transition-duration(.2s);
                            .transition-delay(@fadeInTime + .5s);
                            .transform(scale(1));
                        }
                    }
                }

                &.afisha {
                    overflow: hidden;
                    .row-clearfix();

                    //  purple
                    @color-afisha-light: #E1BEE7; // 100
                    @color-afisha-bold: #7B1FA2; // 700

                    .afisha-types {
                        float: left;
                        width: 225px;
                        height: @full-content-height;
                        position: relative;
                        background: white;

                        .box-sizing(border-box);

                        .types-list {

                            li {
                                display: block;
                                .transition(background .2s);

                                .point {
                                    width: 400px;
                                    height: 400px;
                                    margin-left: -200px;
                                    margin-top: -200px;
                                    background: @color-afisha-light; // 50
                                }

                                .afisha-type {
                                    .count,
                                    .title {
                                        /*margin-left: 14px;*/
                                        text-decoration: none;
                                        display: inline-block;
                                        font-weight: 500;
                                        vertical-align: top;
                                        color: @color-text;
                                        .box-sizing(border-box);
                                    }

                                    .count {
                                        float: right;
                                        font-weight: bold;
                                        margin-left: 0px;
                                        margin-right: 16px;
                                    }
                                }

                                &.active {
                                    background: @color-afisha-bold; // 700

                                    .count,
                                    .title {
                                        color: white;
                                    }
                                }
                            }
                        }

                        .new-event-block {
                            width: 100%;
                            position: absolute;
                            bottom: 0px;
                            left: 0px;
                            text-align: center;
                            padding: 9px 0px;
                            border-top: 1px solid @color-border;
                        }
                    }

                    .afisha-promo-container {
                        @promo-width: 545px;

                        float: left;
                        width: @promo-width;
                        height: @full-content-height;
                        overflow: hidden;
                        position: relative;
                        z-index: 1;

                        .promo {
                            position: absolute;
                            .transition(transform .3s .3s);
                            .transform(translateX(-100%));

                            &.active {
                                z-index: 2;
                                .transition-delay(0s);
                                .transform(translateX(0));
                            }

                            /* Стандартные настройки промо */
                            a {
                                width: @promo-width;
                                height: @full-content-height;

                                .title-holder {
                                    padding: 30px;

                                    .title {
                                        font-size: 45px;
                                    }

                                    .sub-title {
                                        margin-top: 10px;
                                        font-size: 18px;
                                    }
                                }
                            }

                        }
                    }
                }

                &.dom {

                    .content-row {
                        width: 770px;
                        overflow: hidden;

                        .realty-type-list {
                            float: left;
                            width: 300px;
                            height: @full-content-height;
                            position: relative;
                            background: white;

                            .default-menu {
                                @color-dom-text: #4CAF50;
                                @color-dom-text-hover: #388E3C;

                                .type-link {

                                    .name {
                                        display: block;
                                        font-weight: 600;
                                        color: @color-text;
                                        .transition(color .2s);
                                    }

                                    .text {

                                        margin-top: 10px;
                                        display: block;
                                        color: @color-dom-text;
                                        position: relative;
                                        overflow: hidden;

                                        &:after {
                                            content: "Показать последние объявления";
                                            position: absolute;
                                            top: 0px;
                                            left: 0px;
                                            display: block;
                                            color: @color-dom-text;
                                            background: white;
                                            .transition(transform .2s);
                                            .transform(translateX(-100%));
                                        }
                                    }

                                    &:hover {
                                        .name {
                                            color: @color-dom-text-hover;
                                        }

                                        //                                        .text:after {
                                        //                                            .transform(translateX(0px));
                                        //                                        }
                                    }
                                }
                            }

                            .button-holder {
                                border-top: 1px solid @color-border;
                                text-align: center;
                                padding: 8px 0px;
                            }
                        }

                        .la-promo-tile {
                            float: left;
                            width: 470px;
                            overflow: hidden;

                            .title {
                                font-size: 45px;
                            }
                            .sub-title {
                                font-size: 18px;
                            }
                        }
                    }
                }

                &.journal {
                    overflow: hidden;
                    @journal-fade-time: .2s;

                    .journal-block {
                        .transform(translateX(-100%));
                        .transition(all 0s @fadeInTime);

                        .from-slider {
                            width: 770px;
                            height: 197px;
                            background-color: #C5E1A5;
                            display: block;
                            text-align: center;
                            overflow: hidden;

                            .subtitle,
                            .title {
                                display: inline-block;
                                color: black;
                                background: white;
                                text-decoration: none;
                                .transition(all 0s @fadeInTime);
                            }
                            .title {
                                font-size: 35px;
                                line-height: 30px;
                                padding: 5px 10px 15px 10px;
                                margin: 30px 0px 40px -600px;
                                font-weight: 900;
                                .transform(rotate(-45deg) translateX(300px));
                                .text-shadow(1px 1px 0 black, 2px 2px 0 black, 3px 3px 0 black, 4px 4px 0 black;);
                            }
                            .devider-line {
                                display: block;
                            }
                            .subtitle {
                                font-size: 18px;
                                font-weight: bold;
                                font-style: italic;
                                margin-left: -600px;
                                .transform(rotate(-45deg) translateX(300px));
                                padding: 5px;
                            }
                        }

                        .articles {
                            vertical-align: top;
                            text-align: center;
                            margin-top: 22px;

                            .article {
                                display: block;
                                float: left;
                                vertical-align: top;
                                margin: 0px 22px;
                                width: 210px;

                                .pic {
                                    width: 210px;
                                    height: 100px;
                                    display: block;
                                }

                                .text {
                                    height: 82px;
                                    overflow: hidden;
                                }

                                &:last-child {
                                    margin-right: 0px;
                                }
                            }

                        }
                    }

                    &.active {
                        @animation-time: .2s;

                        .journal-block {
                            .transform(translateX(0));
                            .transition(transform @journal-fade-time @fadeInTime);

                            .from-slider {
                                .subtitle,
                                .title {
                                    .transition(all @animation-time @fadeInTime + @journal-fade-time + .1s);
                                    .transform(rotate(0deg) translateX(300px));
                                }
                                .subtitle {
                                    .transition-delay(@fadeInTime + @journal-fade-time + .2s);
                                }
                            }

                            .articles {
                                @fit: @fadeInTime + @journal-fade-time;
                                @animation-delay: @fit + 0.1s;

                                .article {
                                    &:nth-child(1) {
                                        .card-holder {
                                            .transition-delay(@fit);
                                        }
                                    }

                                    &:nth-child(2) {
                                        .card-holder {
                                            .transition-delay(@animation-delay);
                                        }
                                    }

                                    &:nth-child(3) {
                                        .card-holder {
                                            .transition-delay(@animation-time + @animation-delay);
                                        }
                                    }
                                }

                            }

                        }

                    }
                }

                &.market {
                    //  Amber
                    @color-market-light: #FFF9C4; // 100
                    @color-market-bold: #FBC02D; // 700
                    overflow: hidden;

                    .market-block {
                        width: 770px;

                        .rubrics-block {
                            width: 225px;
                            float: left;
                            position: relative;
                            z-index: 2;
                            background: white;
                            height: @full-content-height;

                            ul {
                                li {
                                    position: relative;

                                    .point {
                                        width: 450px;
                                        height: 450px;
                                        margin: -225px;
                                        background: @color-market-light;
                                    }

                                    .icon {
                                        position: absolute;
                                        top: 4px;
                                        right: 16px;

                                        path {
                                            fill: @color-text;
                                        }
                                    }

                                    &.active {
                                        background: @color-market-bold;
                                    }
                                }
                            }

                            .search-input {
                                padding: 17px 12px;
                                display: block;
                                border-bottom: 1px solid @color-border;
                                height: 64px;
                                .box-sizing(border-box);

                                .default-search-pair {

                                    display: inline-block;
                                    height: 30px;
                                    width: 200px;
                                    vertical-align: middle;
                                    position: relative;

                                    .input {

                                        &:focus {
                                            border-color: @color-market-bold;
                                        }
                                    }

                                    .icon {

                                        path {
                                            stroke: @color-market-bold;
                                        }
                                    }
                                }

                            }

                            .all-rubrics-holder {
                                border-top: 1px solid @color-border;
                                padding: 13px 0px;
                                text-align: center;
                                height: 64px;
                                .box-sizing(border-box);
                            }

                        }

                        .market-promo-container {
                            @promo-width: 545px;

                            height: @full-content-height;
                            width: @promo-width;
                            float: left;
                            display: block;
                            position: relative;
                            z-index: 1;
                            overflow: hidden;

                            .promo {
                                position: absolute;
                                .transition(transform .3s .3s);
                                .transform(translateX(-100%));

                                &.active {
                                    z-index: 2;
                                    .transition-delay(0s);
                                    .transform(translateX(0));
                                }

                                /* Стандартные настройки промо */
                                a {
                                    width: @promo-width;
                                    height: @full-content-height;
                                }
                            }
                        }
                    }
                }

                &.job {
                    .content-row {
                        width: 770px;
                        position: relative;

                        .actions-list {
                            float: left;
                            width: 225px;
                            background: white;
                            position: relative;
                            z-index: 4;
                            height: 416px;
                            .z-depth(1);

                            ul li {
                                .row-clearfix();

                                .type-link {
                                    float: left;

                                    span {
                                        .transition(all .2s);
                                    }

                                    .name {
                                        font-weight: bold;

                                    }

                                    .text {
                                        margin-top: 10px;
                                        display: block;
                                        color: @color-link;
                                        position: relative;
                                        overflow: hidden;
                                    }

                                    &:hover {
                                        span {
                                            color: @color-link-hover;
                                        }
                                    }
                                }
                                .add-link {
                                    @size: 24px;

                                    padding: 0px;
                                    float: right;
                                    position: relative;
                                    min-width: @size;
                                    height: @size;
                                    margin: 4px;
                                    border-radius: @size;
                                    overflow: hidden;
                                    text-align: center;

                                    @iw: 14px;
                                    @ih: 2px;

                                    &:before,
                                    &:after {
                                        content: "";
                                        display: block;
                                        width: @iw;
                                        height: @ih;
                                        background: @color-link;
                                        position: absolute;
                                        left: 50%;
                                        top: 50%;
                                        margin-left: -@iw/2;
                                        margin-top: -@ih/2;
                                        .transition(all .2s);
                                    }
                                    &:after {
                                        .transform(rotate(90deg));
                                    }

                                    .name {
                                        margin-right: -100%;
                                        color: white;
                                        line-height: @size;
                                        display: block;
                                        padding: 0px 5px 0px 7px;
                                        display: none;
                                        .transition(all .2s);
                                    }

                                    &:hover {
                                        background: @color-link-hover;

                                        &:before,
                                        &:after {
                                            background: white;
                                        }
                                        &:before {
                                            .transform(rotate(360deg));
                                        }
                                        &:after {
                                            .transform(rotate(450deg));
                                        }
                                        //.name {
                                        //    margin-right: 0px;
                                        //}
                                    }
                                }
                            }
                        }

                        .articles {
                            float: left;
                            width: 545px;

                            .article {
                                padding: 11px 10px;

                                &:last-child {
                                    padding: 12px 10px;
                                }
                            }
                        }
                    }

                }

                &.reference {
                    .content-row {
                        width: 770px;

                        .firms {
                            width: 375px;
                            float: left;
                            z-index: 3;
                            height: 416px;
                            background: url('http://user.vse42.ru/files/ui-557545305a0349.34378330.jpeg') white;
                            //.z-depth(1);

                            .firm-search {
                                padding: 17px 12px;
                                display: block;
                                border-bottom: 1px solid @color-border;
                                height: 64px;
                                margin-top: 140px;
                                background: rgba(255, 255, 255, .9);
                                .box-sizing(border-box);

                                .default-search-pair {
                                    width: 350px;

                                    .input {
                                        width: 350px;

                                        &:focus {
                                            & + .icon {

                                                path {
                                                    stroke-dashoffset: 425;
                                                    stroke-dasharray: 350, 76;
                                                }
                                            }
                                        }
                                    }

                                    .icon {

                                        path {
                                            stroke-dasharray: 75, 350;
                                        }
                                    }
                                }
                            }

                            .button-holder {
                                position: absolute;
                                bottom: 0px;
                                background: white;
                                width: 100%;
                                border-right: 1px solid @color-border;
                            }
                        }

                        .help {
                            width: 395px;
                            float: left;
                            z-index: 3;
                            background: white;

                            &:last-child {
                                z-index: 2;
                            }
                        }

                        .button-holder {
                            padding: 13px 0px 14px 0px;
                            border-top: 1px solid @color-border;
                            text-align: center;
                        }
                    }

                }

                &.pokupka42 {
                    .content-row {
                        width: 770px;

                        .categories {
                            float: left;
                            width: 385px;
                            background: white;
                            position: relative;
                            .z-depth(1);

                            .search-holder {
                                padding: 17px 16px 16px 16px;
                                border-bottom: 1px solid @color-border;

                                .default-search-pair {
                                    width: 350px;

                                    .input {
                                        width: 350px;

                                        &:focus {
                                            & + .icon {

                                                path {
                                                    stroke-dashoffset: 425;
                                                    stroke-dasharray: 350, 76;
                                                }
                                            }
                                        }
                                    }

                                    .icon {

                                        path {
                                            stroke-dasharray: 75, 350;
                                        }
                                    }
                                }
                            }

                            .default-menu {
                                li {
                                    a {
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        padding-right: 16px;
                                    }
                                }
                            }

                            .button-holder {
                                text-align: center;
                                padding: 10px 0px;
                                border-top: 1px solid @color-border;
                                margin-top: 231px;
                            }

                            &-left {
                                z-index: 2;
                            }
                        }
                    }
                }

                &.ny2016 {

                    .news-content-holder {
                        width: 458px;
                        height: 416px;
                        float: left;
                        background: white;
                        .z-depth(1);
                    }

                    .news-prize {
                        width: 312px;
                        float: left;
                        height: 416px;
                        background: white;
                    }

                    .article {
                        display: block;
                        width: 210px;

                        float: left;
                        margin: 12px 0 0 12px;

                        .shadow {
                            height: 190px;
                        }
                    }

                    .button-holder {
                        border-top: 1px solid @color-border;
                        text-align: center;
                        padding: 8px 0px;
                    }

                }
            }
        }
    }

    &__wow-win {
        .logo-row {

            .logo {
                position: relative;

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    background: url('/common-static/img/slim-header/logo-wow.png') no-repeat;
                    background-position: 0 0;
                    left: 37px;
                    top: 8px;
                    width: 272px;
                    height: 47px;
                }

                a {
                    .brand {
                        opacity: 0;
                    }
                }
            }
            .bar {
                box-shadow: 0 0 3px 1px white;
            }
        }
    }
}