@import "material-shadow.less";
@import "prefixer.less";
@import "for.less";

.material-social-icons{
    @border-radius: 3px;
    @icon-width: 50px;
    @icon-height: 50px;
    
    .material-social-icon{
        width: @icon-width;
        height: @icon-height;
        display: inline-block;
        border-radius: @border-radius;
        text-align: center;
        position: relative;
        z-index: 1;
        cursor: pointer;
        width: @icon-width;
        height: @icon-height;
        background-image: url('/common-static/img/material-social-icons/social-completed.png');
        .transition(box-shadow .2s);

        &:hover{
            .z-depth(2);
        }

        @step: -60px;
        @socials: vk,tw,gp,ok,fb,yt,in;

        .for(length(@socials)); .-each(@i) {
            @name: extract(@socials,@i);
            &.@{name} {
                background-position: 0px @step * (@i - 1) ;
            }
        }
    }

}