@import "prefixer.less";
@height: 39px;

@col-link: #222;
@col-link-hover: #0066cc;

@font-size: 13px;

.common-profile-bar{
    height: 39px;
    font-size: 13px;
    .box-sizing(border-box);
    border-bottom: 1px dotted #E6E6E6;

    .holder{

        .actions{
            float: left;
            height: @height;
            line-height: @height;

            .arrow_show{
                text-decoration: none;
                border-bottom: 1px dotted;
            }
        }

        a{
            margin-left: 10px;
            .transition(all .2s);
            text-decoration: none;

            &:first-child{
                margin-left: 0px;
            }

            &:hover{
                span{
                    color: @col-link-hover;
                    border-bottom: 1px solid lighten(@col-link-hover,30%);
                }
            }

            span{
                font-size: @font-size;
                color: @col-link;
                border-bottom: 1px solid lighten(@col-link,50%);
            }
        }

        .userinfo{
            float: right;
            height: @height;

            &:after{
                content: "";
                width: 0px;
                height: 100%;
                display: inline-block;
                vertical-align: middle;
            }

            .auth-enter{
                display: inline-block;
                vertical-align: middle;
                line-height: 17px;
                font-size: 13px;
                border: none;

                span{
                    border-bottom-style: dotted;
                }
            }

            .profile{
                text-decoration: none;

                .avatar{
                    height: @height - 10;
                    width: @height - 10;
                    margin-top: -1px;
                    border-radius: 3px;
                    background: #ccc;
                    display: inline-block;
                    vertical-align: middle;
                }

            }

        }
    }
}

.common-login-panel,.layout.transport{
    text-align: center;
    position: absolute;
    left: 0px;
    top: 0px;
    visibility: hidden;
    width: 100%;

    .shadow{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: url('/common-static/img/1x1blacktrans.png');
        z-index: 10002;
        opacity: 0;
        .transition(all .2s);
    }
    .window{
        position: relative;
        z-index: 10003;
        background: white;
        vertical-align: middle;
        display: inline-block;
        width: 550px;
        opacity: 0;
        margin-top: 0px;
        .transition(all .2s);
        .border-radius(3px);

        .bar{
            background: #e9e9e9;
            padding: 10px;
            position: relative;
            text-align: left;

            .logo-img{
                display: inline-block;
                vertical-align: top;
            }
            
            .logo-text{
                vertical-align: top;
                font-family: "Open sans", sans-serif;
                font-weight: 300;
                margin-left: 10px;
                font-size: 33px;
                line-height: 30px;
                margin-top: 0px;
                letter-spacing: -2px;
                text-transform: lowercase;
                display: inline-block;

                &:first-letter{
                    text-transform: uppercase;
                }
            }

            .close{
                position: absolute;
                top: 10px;
                right: 10px;
                cursor:pointer;
                color: lighten(@col-link,20);

                .icon{
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    vertical-align: middle;
                    position: relative;
                    .transition(all .2s);

                    &:before, &:after{
                        content:"";
                        display: block;
                        width: 10px;
                        height: 2px;
                        background: @col-link;
                        position: absolute;
                        left: 0px;
                        top: 8px;
                        opacity: 1;

                        box-shadow: 10px 0px 0px @col-link;
                        .transition(all .2s);
                    }

                    &:before{
                        top: 6px;
                        left: 0px;
                        .transform(rotate(45deg));
                    }
                    &:after{
                        top: 12px;
                        left: 0px;
                        .transform(rotate(-45deg));
                    }
                }

                &:active{
                    color: @col-link;

                    .icon{
                        &:before,
                            &:after{
                            box-shadow: 82px 0px 0px @col-link;
                            opacity: 0;
                        }

                        &:before{
                            top: -23px;
                            left: -25px;

                        }
                        &:after{
                            top: 35px;
                            left: -25px;

                        }
                    }
                }
            }
        }
    }

    &.visible{
        /*display:block;*/
        visibility: visible;

        .shadow{
            opacity: 1;
        }
        .window{
            margin-top: 100px;
            opacity: 1;
            .transition(all .5s);
        }
    }

}