.footer-push, .vse-footer {
    margin: 0;
    padding: 0;
}
.vse-footer {
    .links {
        margin-top: 10px;
        position: relative;
        a {
            color: #535353;
        }
        .title {
            margin: 5px 0px 0px 0px;
            padding-bottom: 5px;
            font-size: 16px;
        }
        ul {
            border-top: 1px dotted #c7c7c7;
            margin: 0px;
            padding: 5px 0px;
            list-style-type: none;
            li {
                font-size: 12px;
                margin: 5px 0px;
            }
        }
    }
    .logo2 {
        left: -7px;
        top: 8px;
        position: absolute;
    }
    .social {
        margin-bottom: 5px;
        a {
            float:left;
        }
        .soc-icon {
            display: block;
            width: 20px;
            height: 20px;
            background-image: url('/common-static/img/soc-icons.png');
        }
        .soc-icon.vk {
            background-position: 0px -20px;
        }
        .soc-icon.vk:hover {
            background-position: 0px 0px;
        }
        .soc-icon.tw {
            background-position: -20px -20px;
        }
        .soc-icon.tw:hover {
            background-position: -20px 0px;
        }
        .soc-icon.yt {
            background-position: -40px -20px;
        }
        .soc-icon.yt:hover {
            background-position: -40px 0px;
        }
        .soc-icon.ok {
            background-position: -100px -20px;
        }
        .soc-icon.ok:hover {
            background-position: -100px 0px;
        }
        .soc-icon.fb {
            background-position: -80px -20px;
        }
        .soc-icon.fb:hover {
            background-position: -80px 0px;
        }
    }
    .info {
        margin: 10px 0px 20px 0px;
        font-size: 12px;
    }
    .info.visible {
        display: block;
    }
    .copyright {
        font-size: 12px;
        float: left;
    }
    .contacts {
        float: left;
    }
    .restriction {
        .sign {
            font-size: 16px;
            background: black;
            font-weight: bold;
            border-radius: 3px;
            padding: 3px 0px;
            display: inline-block;
            width: 100%;
            text-align: center;
            position: relative;
            a {
                text-decoration: none;
                color:white;
            }
        }
    }
}
.change-seo-text {
    padding: 10px;
    border: 1px dashed black;
    margin: 10px 0px;
    background: white;
    input[type="text"] {
        width: 300px;
        color: black;
        outline: 10px solid transparent;
        transition: all 0.1s;
    }
    input[type="text"]:focus {
        outline: 2px solid #76b8ec;
        transition: all 0.2s;
    }
    input[type="submit"] {
        border: none;
        background: #ccc;
        padding: 3px 10px;
        cursor: pointer;
        background: #008d00;
        color: white;
        font-weight: bold;
    }
    .description {
        font-size: 12px;
        margin-bottom: 5px;
    }
}
